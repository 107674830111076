import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import { HospitalMenuForm } from './HospitalMenuForm';
import Loading from '../../Common/Loading';
import { isEmpty } from '../../../utils/ObjectUtils';
import { GET_HOSPITALS, GET_HOSPITAL } from '../../../graphql/queries';
import { UPDATE_HOSPITAL } from '../../../graphql/mutations';
import useStyles from '../styled/HospitalFormMakeStyles';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { ScrollableContainer } from '../../Common/ScrollableContainer';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';

export const HospitalMenuEdit = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const [serverError, setServerError] = useState(false);
  const { loading, data } = useQuery(GET_HOSPITAL, {
    variables: { uuid: hospitalUuid },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [updateHospital, { loading: updating }] = useMutation(UPDATE_HOSPITAL, {
    onCompleted({ updateHospital: newData }) {
      dispatch({
        type: 'CHANGE_HOSPITAL',
        current: newData,
      });
      themeContext.setPrimaryColor(data.hospital.mainColor);
      toast(`${t('settings.updated.successfully')}...`, { className: 'toast-info' });
    },
    refetchQueries: [{ query: GET_HOSPITALS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      const updateHospitalInput = {
        uuid: data.hospital.uuid,
        name: values.name,
        menuOptionAIStats: values.menuOptionAIStats,
        menuOptionStudies: values.menuOptionStudies,
        menuOptionMedicalCases: values.menuOptionMedicalCases,
        menuOptionPatients: values.menuOptionPatients,
        menuOptionGroups: values.menuOptionGroups,
        menuOptionProtocols: values.menuOptionProtocols,
        menuOptionVirtualVisits: values.menuOptionVirtualVisits,
        menuOptionFiles: values.menuOptionFiles,
        menuOptionSettings: values.menuOptionSettings,
      };
      await updateHospital({ variables: { updateHospitalInput } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (Object.keys(formikErrors).length === 0 && formikErrors.constructor === Object) {
        setServerError(networkErrorParse(errors));
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="hospital.settings" />
      </Navbar>

      <Box className={classes.container}>
        <ScrollableContainer padding="1.25em 0 0">
          <Paper elevation={2} className={classes.wrapper}>
            {(loading && isEmpty(data)) && <Loading />}
            {data && data.hospital && (
              <HospitalMenuForm
                hospital={data.hospital}
                submitLabel={t('update')}
                handleSubmit={handleSubmit}
                serverError={serverError}
                submitting={updating}
              />
            )}
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
