import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { DialogActions, Grid, Typography } from '@material-ui/core';

import Loading from '../../Common/Loading';
import { Form } from '../../Common/styled/Form';
import { TextFieldUI } from '../../../componentsUI/TextField';
import { ButtonUI } from '../../../componentsUI/Button';
import { canI } from '../../../casl/Can';
import { CheckBoxUI } from '../../../componentsUI/CheckBox';
import { AlertUI, AlertContainerUI } from '../../../componentsUI/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  form: {
    '& .MuiGrid-grid-xs-12': {
      padding: '0 8px',
    },
    '& .MuiIconButton-root': {
      padding: '5px 5px',
      marginRight: 4,
    },
    '& .MuiGrid-container .MuiGrid-grid-xs-12:last-child': {
      height: 24,
    },
  },
  group: {
    marginBottom: 4,
    color: theme.palette.primary.light,
    fontSize: '.875em',
  },
  name: {
    marginBottom: 24,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}30`,
  },
  actions: {
    marginTop: 8,
  },
}));

export const HospitalMenuForm = ({
  hospital,
  submitLabel,
  handleSubmit = () => {},
  submitting,
  formName = 'menuOptionsFormEdit',
}) => {
  const { t } = useTranslation();
  const availableAdvancedTools = useSelector((state) => state.hospital.availableAdvancedTools);
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
    menuOptionAIStats: Yup.bool(),
    menuOptionStudies: Yup.bool(),
    menuOptionMedicalCases: Yup.bool(),
    menuOptionPatients: Yup.bool(),
    menuOptionGroups: Yup.bool(),
    menuOptionProtocols: Yup.bool(),
    menuOptionVirtualVisits: Yup.bool(),
    menuOptionFiles: Yup.bool(),
    menuOptionSettings: Yup.bool(),
  });

  const initialValues = {
    name: hospital ? hospital.name : '',
    menuOptionAIStats: hospital ? hospital.menuOptionAIStats : false,
    menuOptionStudies: hospital ? hospital.menuOptionStudies : false,
    menuOptionMedicalCases: hospital ? hospital.menuOptionMedicalCases : false,
    menuOptionPatients: hospital ? hospital.menuOptionPatients : false,
    menuOptionGroups: hospital ? hospital.menuOptionGroups : false,
    menuOptionProtocols: hospital ? hospital.menuOptionProtocols : false,
    menuOptionVirtualVisits: hospital ? hospital.menuOptionVirtualVisits : false,
    menuOptionFiles: hospital ? hospital.menuOptionFiles : false,
    menuOptionSettings: hospital ? hospital.menuOptionSettings : false,
  };

  const handleFormSubmit = (event, props) => {
    event.preventDefault();

    const values = {
      ...props.values,
      menuOptionVirtualVisits: props.values.menuOptionProtocols && props.values.menuOptionVirtualVisits,
    };
    handleSubmit(values, props).then();
  };

  const goMain = () => navigate('/hospital/settings/edit');

  const canEdit = hospital ? canI('edit', hospital) : true;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {(props) => (
        <Form className={classes.form} id={formName} onSubmit={(event) => handleFormSubmit(event, props)}>
          <Grid container spacing={2}>
            {!canEdit && (
              <AlertContainerUI>
                <AlertUI severity="info" title={t('unauthorized')}>
                  {t('you.are.not.allowed.to.change.these.settings')}
                </AlertUI>
              </AlertContainerUI>
            )}
            <Grid item xs={12} className={classes.name}>
              <TextFieldUI name="name" label={t('hospital.name')} props={props} classes={classes} readonly />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.group}>
                {t('hospital.settings.option.menu')}
              </Typography>
            </Grid>
            {!!availableAdvancedTools.length && (
              <Grid item xs={12}>
                <CheckBoxUI
                  name="menuOptionAIStats"
                  label={t('show.advanced.tool.use.stats')}
                  disabled={!canEdit}
                  props={props}
                  checked={!!props.values.menuOptionAIStats}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionStudies"
                label={t('show.studies.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionStudies}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionMedicalCases"
                label={t('show.medicalCases.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionMedicalCases}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionPatients"
                label={t('show.patients.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionPatients}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionGroups"
                label={t('show.groups.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionGroups}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionProtocols"
                label={t('show.protocol.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionProtocols}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionVirtualVisits"
                label={t('show.virtual.visit.option')}
                props={props}
                checked={!!props.values.menuOptionProtocols && !!props.values.menuOptionVirtualVisits}
                disabled={!props.values.menuOptionProtocols || !canEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionFiles"
                label={t('show.files.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionFiles}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBoxUI
                name="menuOptionSettings"
                label={t('show.settings.option')}
                disabled={!canEdit}
                props={props}
                checked={!!props.values.menuOptionSettings}
              />
            </Grid>
            {canEdit && (
              <Grid item xs={12}>
                {submitting && <Loading />}
              </Grid>
            )}
          </Grid>
          <DialogActions className={classes.actions}>
            <ButtonUI
              variant="outlined"
              disabled={submitting}
              color="primary"
              onClick={goMain}
            >
              {t('go.back')}
            </ButtonUI>
            {canEdit && (
              <ButtonUI
                variant="contained"
                disabled={submitting}
                color="primary"
                form={formName}
                type="submit"
              >
                {submitLabel}
              </ButtonUI>
            )}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
